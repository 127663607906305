import React, { Component } from "react";
import { Link } from "react-scroll";


export default class Header extends Component {
  
  render() {
    return (
      <header>
        <h1>
          Places Reveal <span className="our">Our</span> Purpose
        </h1>
        <h3>10/29 10:00am</h3>
        <div className="header-content">
          <h4 className="foundation">2019 Obama Foundation Summit</h4>
          <a className="calendar" href="https://www.google.com">Add to Calendar</a>
        </div>
        
          <Link
          className="down-arrow"
          activeClass="active"
          to="livestream-anchor"
          spy={true}
          smooth={true}
          offset={-39}
          duration={200}
        >
        </Link>
          
      </header>
    );
  }
}