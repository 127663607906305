import React, { Component } from "react";
import { Link, animateScroll as scroll } from "react-scroll";

export default class Navbar extends Component {
  scrollToTop = () => {
    scroll.scrollToTop();
  };

  render() {
    return (
      <nav className="nav contain-1680" id="navbar">
        
          <div className = "nav-content border">
            <ul className="nav-items">
              <li className="nav-item">
                <Link
                  activeClass="active"
                  to="livestream-anchor"
                  spy={true}
                  smooth={true}
                  offset={-39}
                  duration={500}
                >
                  Livestream
                </Link>
              </li>
              <li className="nav-item">
                <Link
                  activeClass="active"
                  to="program-anchor"
                  spy={true}
                  smooth={true}
                  offset={-39}
                  duration={500}
                >
                  Program
                </Link>
              </li>
              <li className="nav-item">
                <Link
                  activeClass="active"
                  to="updates-anchor"
                  spy={true}
                  smooth={true}
                  offset={-39}
                  duration={500}
                >
                  Updates
                </Link>
              </li>
              <li className="nav-item">
                <Link
                  activeClass="active"
                  to="stories-anchor"
                  spy={true}
                  smooth={true}
                  offset={-39}
                  duration={500}
                >
                  Stories
                </Link>
              </li>
              <li className="nav-item donate">
                <Link
                  activeClass="active"
                  to="donate-anchor"
                  spy={true}
                  smooth={true}
                  offset={-39}
                  duration={500}
                >
                  Donate
                </Link>
              </li>
            </ul>
            <p
              // src={logo}
              className="nav-logo"
              alt="Logo"
              onClick={this.scrollToTop}
            ></p>
          </div>
        
      </nav>
    );
  }
}
