import React, { Component } from "react";
import Header from "./Header";
import Livestream from "./Livestream";
import Featured from "./Featured";

class Main extends Component {
  
  render() {
    return (
      <main className ="contain-1680">
        <Header />
        <Livestream />
        <Featured />
      </main>
    );
  }
}

export default Main;
