import React, { Component } from "react";

export default class Featured extends Component {
  constructor() {
    super();
    this.state = {
      featuredSessions: []
    };
  }

  componentDidMount() {
    fetch("https://summit-data.obama.org/wp-json/of/v1/summit/")
      .then(response => response.json())
      .then(data => {
        data.featuredSessions.map(session => {
          return console.log(session);
        });

        let featuredSessions = data.featuredSessions.map(session => {
          return session;
        });

        this.setState({
          featuredSessions: featuredSessions
        });

        console.log("state", this.state);
      });
  }



  render() {

       
        
    return (
      <section className="feature" id="program-anchor">
        <h4>Featured Sessions</h4>
        <ul className="featureCardContainer">

          {this.state.featuredSessions.slice(0, 4).map(
            ({ title, speakers, moderators, photos }, i) => (
              <li key={i} className={"featureCard border listItem" + i}>
                <div className="featureContent">
                  <h5>{title}</h5>
                  <div className="feature-details">
                    <div className="speakers">
                      {speakers.map((speaker, j) => (
                        <p key={j}>{speaker}</p>
                      ))}
                    </div>

                    {moderators.map((moderator, j) => (
                      <p key={j}>
                        In conversation with <br />
                        {moderator}
                      </p>
                    ))}
                  </div>
                </div>
                <img src={photos[0].url} alt={title} />
              </li>
            )
          )}

          {this.state.featuredSessions.slice(4).map(
            ({ title, speakers, moderators, photos }, i) => (
              <li key={i} className="featureCard border listItem4">
                <div className="featureContent">
                  <h5>{title}</h5>
                  <div className="feature-details">
                    <div className="speakers">
                      {speakers.map((speaker, j) => (
                        <p key={j}>{speaker}</p>
                      ))}
                      {moderators.map((moderator, j) => (
                      <p key={j}>
                        {moderator}
                      </p>
                    ))}

                    </div>
                    <p>Obama Foundation Program Participants</p>
                  </div>
                </div>
                <img src={photos[0].url} alt={title} />
              </li>
            )
          )}

        </ul>

      </section>
    );
  }
}


