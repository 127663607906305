import React, { Component } from "react";
import moment from "moment";

export default class Livestream extends Component {
  constructor() {
    super();
    this.state = {
      livestream: [],
      compare: ""
    };
  }

  componentDidMount() {
    fetch("https://summit-data.obama.org/wp-json/of/v1/summit/")
      .then(response => response.json())
      .then(data => {
        // console.log(data);
        let livestream = data.livestream.startDate;
        const then = moment(livestream);
        // console.log("then", then);
        const now = moment();
        // console.log("now", now);
        const compare = moment(now).isAfter(then); // true

        this.setState({
          livestream: livestream,
          compare: compare
        });

        // console.log("compare", compare);
        // console.log("state", this.state);
      });
  }

  render() {
    const isPast = this.state.compare;
    let counter;

    if (isPast) {
      counter = (
        <div className="livestream-content">
          <h2>0d 00h</h2>
          <a href="https://www.google.com">
            <p className ="arrow-text">Arrow</p>
          </a>
          <h2>00m 00s</h2>
        </div>
      );
    } else {
      counter = (
        <div className="livestream-content">
          <a href="https://www.google.com">
            <p className ="arrow-text">Arrow</p>
          </a>
        </div>
      );
    }

    return (
      <section className="livestream" id="livestream-anchor">
        <h4>Livestream starts in</h4>
        {counter}
      </section>
    );
  }
}